import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import './PageHeader.css'
import { Link } from 'gatsby'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = '',
  to,
  relative
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div
      className={`PageHeader relative ${className} ${
        relative ? 'img' : 'no-img'
      }`}
    >
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <div className="container relative">
        <div>
          <h1 className="PageHeader--Title">{title}</h1>
        </div>
        <div className={to ? 'banner-type-button' : 'banner-type-subtitle'}>
          {subtitle &&
            (to ? (
              relative ? (
                <Link to={to} className="banner-button">
                  {subtitle}
                </Link>
              ) : (
                <a href={to} className="banner-button">
                  {subtitle}
                </a>
              )
            ) : (
              <Content className="PageHeader--Subtitle" src={subtitle} />
            ))}
        </div>
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  to: PropTypes.string,
  relative: PropTypes.bool
}

export default PageHeader
