import React from 'react'
import { graphql, Link } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import PostSection from '../components/PostSection'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = posts => {
  const now = Date.now()
  return posts.filter(post => Date.parse(post.date) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'postCategories'
  const byCategory = post =>
    post.categories &&
    post.categories.filter(cat => cat.category === title).length
  return isCategory ? posts.filter(byCategory) : posts
}

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  title,
  subtitle,
  featuredImage,
  title2,
  subtitle2,
  featuredImage2,
  title3,
  subtitle3,
  title4,
  subtitle4,
  featuredImage4,
  body,
  headline,
  sponsors,
  posts,
  contentType
}) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
      relative={true}
    />

    <section className="section headline">
      <div className="container">
        <Content source={headline} />
      </div>
    </section>

    <section className="section recent-news">
      <div className="container">
        <h2 className="sub-header">Siste nytt</h2>
        <PostSection
          posts={
            posts && !!posts.length
              ? byCategory(byDate(posts.slice(0, 3)), title, contentType)
              : []
          }
        />
        <div className="spacer">
          <Link className="internal-link" to="/blog">
            Flere nyheter
          </Link>
        </div>
      </div>
    </section>

    <section className="section sponsors">
      <div className="container">
        <h2 className="sub-header">Sponsorer</h2>
        <Content source={sponsors} />
      </div>
    </section>

    <PageHeader
      small
      title={title2}
      subtitle={subtitle2}
      backgroundImage={featuredImage2}
      to="/om-rennet"
      relative={true}
    />

    <PageHeader
      small
      title={title3}
      subtitle={subtitle3}
      to="https://signup.eqtiming.no/?Event=langfjordrennet"
      relative={false}
    />

    <PageHeader
      small
      title={title4}
      subtitle={subtitle4}
      backgroundImage={featuredImage4}
      to="/resultatlister"
      relative={true}
    />
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page, posts } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate
      {...page}
      {...page.frontmatter}
      body={page.html}
      posts={posts.edges.map(post => ({
        ...post.node,
        ...post.node.frontmatter,
        ...post.node.fields
      }))}
    />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        title2
        subtitle2
        featuredImage2
        title3
        subtitle3
        title4
        subtitle4
        featuredImage4
        headline
        sponsors
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage
          }
        }
      }
    }
  }
`
